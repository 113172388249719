import React from "react";
import "./ServicesSection.css";
import service1 from "../images/Image 1.png"
import service2 from "../images/Image  --lummi2.png"
import service3 from "../images/Image  --lummi3.png"

const ServicesSection = () => {
  return (
    <div className="services-section">
      <h2 className="services-title">Explore Our Services</h2>
      <p className="services-subtitle">Comprehensive Writing Solutions</p>

      <div className="services-cards">
        {/* Card 1 */}
        <div className="service-card">
          <img
            src= {service1}
            alt="Service 1"
            className="service-card-image"
          />
          <div className="service-card-content">
            <p className="service-highlight">Your success is our mission</p>
            <h3 className="service-heading">
              Tailored writing services for every academic need
            </h3>
            <p className="service-description">
              Quality writing at your fingertips
            </p>
            <a href="#get-started" className="service-link">
              Get Started Now
            </a>
          </div>
        </div>

        {/* Card 2 */}
        <div className="service-card">
          <img
            src= {service2}
            alt="Service 2"
            className="service-card-image"
          />
          <div className="service-card-content">
            <p className="service-highlight">Why Choose Us?</p>
            <h3 className="service-heading">Expertise You Can Trust</h3>
            <p className="service-description">
              Our team of experienced writers is here to assist you.
            </p>
            <a href="#learn-more" className="service-link">
              Learn More
            </a>
          </div>
        </div>

        {/* Card 3 */}
        <div className="service-card">
          <img
            src={service3}
            alt="Service 3"
            className="service-card-image"
          />
          <div className="service-card-content">
            <p className="service-highlight">Fast and Reliable</p>
            <h3 className="service-heading">
              We prioritize your deadlines and quality.
            </h3>
            <p className="service-description">
              Experience the difference with our dedicated support.
            </p>
            <a href="#contact-us" className="service-link">
              Contact Us Today
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServicesSection;
