import React from "react";
import "./ContactForm.css";

const ContactForm = () => {
  return (
    <div className="contact-container">
      <div className="form-wrapper">
        <h1 className="title">Reach Out to Us</h1>
        <p className="subtitle">We're here to help you</p>
        <form className="contact-form">
          <label htmlFor="name">Full Name</label>
          <input type="text" id="name" placeholder="Enter your name" />

          <label htmlFor="email">Email Address</label>
          <input type="email" id="email" placeholder="Enter your email" />

          <label htmlFor="message">Your Message</label>
          <textarea id="message" placeholder="Type your message here" />

          <button type="submit">Send Message</button>
        </form>
        <div className="contact-email">
          <span>Email: </span>
          <a href="mailto:info@studentwritepro.com">info@studentwritepro.com</a>
        </div>
      </div>
    </div>
  );
};

export default ContactForm;
