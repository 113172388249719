import React from 'react';
import './JoinUs.css';
import join1 from "../images/Image.png"
import join2 from "../images/Person thinking.png"
import join3 from "../images/Cartoon desk.png"
import join4 from "../images/Student focused.png"
import join5 from "../images/Girl studying.png"
import join6 from "../images/4th image.png"

const JoinUs = () => {
  return (
    <div className="container">
      <div className="image-grid">
        <img src={join1} alt="Student writing" className="grid-image" />
        <img src={join2} alt="Student writing" className="grid-image" />
        <img src={join3} alt="Student writing" className="grid-image" />
        <img src={join4} alt="Student writing" className="grid-image" />
        <img src={join5} alt="Student writing" className="grid-image" />
        <img src={join6} alt="Student writing" className="grid-image" />

      </div>
      <div className="text-section">
        <h1>Your academic partner for success</h1>
        <p>
          We provide tailored writing services to help students excel in their
          assignments.
        </p>
        <button className="cta-button">Join Us Now</button>
      </div>
    </div>
  );
};

export default JoinUs;
