import React from "react";
import "./LandingPage.css";
import img1 from "../images/Image Container.png"

const LandingPage = () => {
  return (
    <div className="landing-page">
      <div className="hero-section">
        <h1 className="hero-title">
          Crafting your academic <br/>
          success, one page at a time. 
      
        </h1>
        <div className="hero-image-wrapper">
          <img
            className="hero-image"
            src={img1}
            alt="Student writing at a desk"
          />
        </div>
        <p className="hero-description">
          Transforming ideas into well-structured documents that meet your
          academic needs.
        </p>
        <button className="cta-button">Get Expert Help Now</button>
      </div>
    </div>
  );
};

export default LandingPage;
