import React from "react";
import "./Testimonials.css";
import img from "../images/Image --lummi --category=avatars.png"
import img1 from "../images/Image --lummi --category=avatars2.png"

const Testimonials = () => {
  return (
    <div className="testimonials-container">
      <h1 className="section-title">What Our Students Say</h1>
      <div className="testimonials-wrapper">
        {/* Testimonial 1 */}
        <div className="testimonial-card">
          <img
            src={img} // Replace with your image path
            alt="Jane Doe"
            className="avatar"
          />
          <div className="testimonial-content">
            <p className="testimonial-text">
              "The writing service helped me complete my thesis on time and with great quality!"
            </p>
            <p className="testimonial-name">Jane Doe, Graduate Student</p>
          </div>
        </div>

        {/* Testimonial 2 */}
        <div className="testimonial-card">
          <img
            src={img1} // Replace with your image path
            alt="John Smith"
            className="avatar"
          />
          <div className="testimonial-content">
            <p className="testimonial-text">
              "I couldn't have finished my assignments without their support. Highly recommend!"
            </p>
            <p className="testimonial-name">John Smith, Undergraduate Student</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Testimonials;
