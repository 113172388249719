import React from 'react';
import ResponsiveNavBar from './screens/ResponsiveNavBar';
import LandingPage from "./screens/LandingPage";
import JoinUs from './screens/JoinUs';
import Pricing from './screens/Pricing';
import ServicesSection from "./screens/ServicesSection";
import Footer from './screens/footer';
import ContactForm from './screens/ContactForm';
import Testimonials from './screens/Testimonials';



function App() {
  return (
    <div className="App">
      <ResponsiveNavBar />
      <LandingPage/>
      <JoinUs />
      <Pricing />
      <ServicesSection />
      <Testimonials />
      <ContactForm />
      <Footer />

    </div>
  );
}

export default App;
