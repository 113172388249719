import React from 'react';
import './Pricing.css';

const Pricing = () => {
  return (
    <div className="pricing-container">
      <h1 className="pricing-title">Affordable Plans for Everyone</h1>
      <p className="pricing-subtitle">
        Discover our competitive pricing that fits your budget and needs.
      </p>

      <div className="pricing-cards">
        {/* Light Card */}
        <div className="pricing-card light-card">
          <h2 className="price">
            $5.99<span>/month</span>
          </h2>
          <p className="description">Embark on your writing journey with us.</p>
          <ul className="features">
            <li>✔ Unlimited Revisions</li>
            <li>✔ Expert Writers</li>
            <li>✔ Fast Turnaround</li>
          </ul>
          <button className="cta-button">Start Writing</button>
        </div>

        {/* Dark Card */}
        <div className="pricing-card dark-card">
          <h2 className="price">
            $5.99<span>/month</span>
          </h2>
          <p className="description">Embark on your writing journey with us.</p>
          <ul className="features">
            <li>✔ Unlimited Revisions</li>
            <li>✔ Expert Writers</li>
            <li>✔ Fast Turnaround</li>
          </ul>
          <button className="cta-button">Start Writing</button>
        </div>
      </div>
    </div>
  );
};

export default Pricing;
