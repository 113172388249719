import React from 'react';
import './ResponsiveNavBar.css';

const ResponsiveNavBar = () => {
  return (
    <nav className="navbar">
      <div className="navbar__logo"> <strong>W</strong>ritting<strong>P</strong>ro</div>
      <ul className="navbar__links">
        <li>Home</li>
        <li>About</li>
        <li>Services</li>
        <li>Contact</li>
      </ul>
      <button className="navbar__cta">Reach Us</button>
    </nav>
  );
};

export default ResponsiveNavBar;
